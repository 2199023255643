import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { Aspect } from '@web/components/media/aspectRatios';
import { QBankImage } from '@web/components/qbank-image/qbank-image';
import { QBankVector } from '@web/components/qbank-vector/qbank-vector';
import { QBankVideo } from '@web/components/qbank-video/qbank-video';
import { Richtext } from '@web/components/richtext/richtext';
import { Theme } from '@web/components/theme/theme';
import { UmbracoAction } from '@web/components/umbraco-action/umbraco-action';

import { HeroAlignment } from '@web/services/umbraco/types/basic/Alignment';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { QBankImageType, QBankMediaType, QBankVectorType, QBankVideoType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { SmallColumns } from '@web/services/umbraco/types/basic/sizes';
import { Action } from '@web/services/umbraco/types/elements/Action';

import ContentGridWidth from './content-grid-width';

import styles from './content-grid-block.module.scss';

const blockName = 'contentGridBlock' as const;

export interface ContentGridBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: HTMLString;
			gridItems: GridItemBlock[];
		},
		{
			width?: SmallColumns;
			alignment?: HeroAlignment;
		}
	>;
}

type GridItemBlock = UmbracoBlock<
	'contentGridItem',
	{
		title?: string;
		bodyText: HTMLString;
		media?: QBankImageType | QBankVectorType | QBankVideoType;
		actions: Action[];
	}
>;

export function ContentGridBlock({ block: { contentProperties, settingsProperties } }: ContentGridBlockProps) {
	const { title, gridItems } = contentProperties || {};
	const { width = SmallColumns.C2, alignment } = settingsProperties || {};
	const columns = width || ContentGridWidth.TwoColumns;

	return (
		<Theme className={styles.theme}>
			<Container className={styles[`container__${alignment}`]}>
				{title && (
					<h2 dangerouslySetInnerHTML={{ __html: title }} />
				)}
				<div className={cn(styles.content_grid, styles[`content_grid__${columns}`])}>
					{gridItems.map(({ key, contentProperties: content }) => (
						<GridItem key={key} content={content} />
					))}
				</div>
			</Container>
		</Theme>
	);
}

ContentGridBlock.blockName = blockName;

function GridItem({ content }: { content: GridItemBlock['contentProperties'] }) {
	const { media, title, bodyText, actions } = content;

	return (
		<div>
			{media && media.type === QBankMediaType.Image && (
				<QBankImage
					media={media}
					aspectRatio={Aspect['4:3']}
					className={cn(styles.item__media)}
					layout="fill"
					sizes="(min-width: 1280px) 25vw, (min-width: 768px) 30vw, 80vw"
				/>
			)}
			{media && media.type === QBankMediaType.Vector && (
				<QBankVector
					media={media}
					className={cn(styles.item__media)}
					layout="fill"
					objectFit="contain"
					sizes="(min-width: 1280px) 25vw, (min-width: 768px) 30vw, 80vw"
				/>
			)}
			{media && media.type === QBankMediaType.Video && (
				<QBankVideo
					className={cn(styles.item__media)}
					aspectRatio={Aspect['4:3']}
					media={media}
				/>
			)}
			<h3 className="h4">{title}</h3>
			{bodyText && <Richtext content={bodyText} />}
			{actions.length > 0 && (
				<div className={styles.item__actions}>
					{actions.map((item) => {
						return (
							<div key={item.key} className={styles.item__action}>
								<UmbracoAction action={item} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
